<template>
  <Loader v-if="isLoading" />

  <v-card
    v-else
    :color="workspaceLayerColor"
    class="mx-auto my-md-10 pt-10 text-center"
    width="100%"
    max-width="45rem"
  >
    <v-card-text class="px-5 px-md-10">
      <h1 class="headline font-weight-black secondary--text mb-2">
        {{ $t('aml.liveliness') }}
      </h1>
    </v-card-text>

    <v-card-text class="px-5 px-md-15">
      <v-row align="center" justify="center">
        <v-col cols="auto" class="text-caption">
          <span class="subtitle-1 font-weight-bold secondary--text">
            {{ $t('auths.liveliness.humanity') }}
          </span>
        </v-col>
      </v-row>
    </v-card-text>

    <div class="mx-auto pb-5">
      <SendToPhoneBox
        v-if="!$vuetify.breakpoint.mobile"
        v-model="sendToPhone"
        :info-text="$t('auths.liveliness.phone.can_send')"
        :sent-text="$t('auths.liveliness.phone.sent')"
        class="pb-1"
      />
    </div>

    <v-card-text v-if="!cameraError" class="pt-0 px-5 px-md-15">
      <v-row align="center" justify="center">
        <v-col cols="auto" class="text-caption">
          <span class="subtitle-1 font-weight-bold secondary--text">
            {{ $t('auths.liveliness.humanity_info') }}
          </span>
        </v-col>
      </v-row>
    </v-card-text>

    <LivenessCheckCamera v-model="cameraError" />
  </v-card>
</template>

<script>
import Loader from '@/components/Loader';
import SendToPhoneBox from '@verification/components/SendToPhoneBox';
import LivenessCheckCamera from '@verification/components/LivenessCheckCamera';
import { mapGetters } from 'vuex';

export default {
  name: 'Liveliness',
  components: {
    LivenessCheckCamera,
    Loader,
    SendToPhoneBox,
  },
  data() {
    return {
      loaded: false,
      sendToPhone: false,
      cameraError: false,
    };
  },
  computed: {
    ...mapGetters({
      workspaceLayerColor: 'auths/workspaceLayerColor',
    }),
    isLoading() {
      return !this.loaded;
    },
  },
  watch: {
    cameraError: {
      handler: function (hasCameraError) {
        if (hasCameraError) {
          this.sendToPhone = true;
        }
      },
    },
  },
  mounted() {
    const self = this;
    setTimeout(() => {
      self.loaded = true;
    }, 1000);
  },
};
</script>

<style lang="scss" scoped></style>
