<template>
  <span ref="bullet" class="moving-point"></span>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'MovingPoint',
  data() {
    return {
      container: null,
      movements: [
        { top: 5, left: 4, dotPosition: 'TOP_LEFT' },
        { top: 94, left: 96, dotPosition: 'BOTTOM_RIGHT' },
        { top: 5, left: 96, dotPosition: 'TOP_RIGHT' },
        { top: 94, left: 4, dotPosition: 'BOTTOM_LEFT' },
        { top: 94, left: 96, dotPosition: 'BOTTOM_RIGHT' },
        { top: 5, left: 4, dotPosition: 'TOP_LEFT' },
        { top: 50, left: 50, dotPosition: 'selfie' },
      ],
      // TODO - better random positioning
      // .map((a) => ({ sort: Math.random(), value: a }))
      // .sort((a, b) => a.sort - b.sort)
      // .map((a) => a.value),
    };
  },
  props: {
    containerId: {
      type: String,
      required: true,
    },
  },
  mounted() {
    this.container = document.querySelector(`#${this.containerId}`);

    const self = this;
    this.$nextTick(() => {
      setTimeout(() => {
        self.goToNextStep();
      }, 1000);
    });
  },
  methods: {
    ...mapActions({
      setDotState: 'authsLiveliness/setDotState',
      setMovingDone: 'authsLiveliness/setMovingDone',
    }),
    goToNextStep() {
      if (0 === this.movements.length) {
        this.setMovingDone();
        return;
      }

      const self = this;
      setTimeout(() => {
        const pointPosition = self.movements.shift();
        self.setDotState(pointPosition).then((resp) => {
          self.moveToPosition(resp);
          setTimeout(() => {
            self.goToNextStep();
            self.$emit('take-snapshot', pointPosition.dotPosition);
          }, 750);
        });
      }, 1000);
    },
    moveToPosition(position) {
      this.$refs.bullet.style.left = `calc(${position.left}% - 20px)`;
      this.$refs.bullet.style.top = `calc(${position.top}% - 20px)`;
    },
  },
};
</script>

<style lang="scss" scoped>
.moving-point {
  position: absolute;
  height: 2.5rem;
  top: calc(50% - 1.25rem);
  left: calc(50% - 1.25rem);
  background-color: #d50000;
  border: 0.5rem solid #b71c1c;
  border-radius: 50%;
  width: 2.5rem;
  transition: top 1s cubic-bezier(0.42, -0.3, 0.78, 1.25),
    left 1s cubic-bezier(0.42, -0.3, 0.78, 1.25);
}
</style>
