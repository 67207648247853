<template>
  <div v-if="!cameraHasError" class="mx-auto">
    <v-card-text class="px-5 px-md-12 pt-0 pt-md-0 pb-5 pb-md-5">
      <WebCamera
        ref="camera"
        photo-type="liveness"
        default-facing-mode="user"
        :select-first-device="true"
        @started="onStarted"
        @stopped="onStopped"
        @error="onError"
        @cameras="onCameras"
      />
    </v-card-text>

    <div class="pt-5 pb-10">
      <v-btn color="primary" x-large dark :loading="dialog" @click="onStart">
        {{ $t('auths.liveliness.start') }}
      </v-btn>
    </div>

    <v-dialog :value="dialog" max-width="800px" :fullscreen="$vuetify.breakpoint.mobile">
      <v-card dark>
        <v-card-title class="headline lighten-2">
          {{ $t('auths.liveliness.watch_dot') }}
        </v-card-title>

        <v-card-text class="pt-3">
          <div id="camera-box" class="camera-box">
            <WebCamera
              ref="camera"
              photo-type="liveness"
              default-facing-mode="user"
              :select-first-device="true"
              @started="onStarted"
              @stopped="onStopped"
              @error="onError"
              @cameras="onCameras"
            >
              <template v-slot:moving-point>
                <MovingPoint
                  v-if="movePoint"
                  container-id="camera-box"
                  @take-snapshot="takeSnapshot"
                />
              </template>
            </WebCamera>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import gql from 'graphql-tag';
import WebCamera from '@/packages/web-camera/WebCamera';
import MovingPoint from '@verification/components/MovingPoint';

export default {
  name: 'LivenessCheckCamera',
  components: {
    WebCamera,
    MovingPoint,
  },
  props: {
    value: Boolean,
  },
  data() {
    return {
      dialog: false,
      loaded: false,
      isCameraOpen: false,
      movePoint: false,
    };
  },
  computed: {
    ...mapGetters({
      dotState: 'authsLiveliness/dotState',
      images: 'authsLiveliness/images',
      isMovingDone: 'authsLiveliness/isMovingDone',
    }),
    cameraHasError: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    ...mapActions({
      addImage: 'authsLiveliness/addImage',
      uploadSelfieImage: 'authsLiveliness/uploadSelfieImage',
    }),
    async takeSnapshot(dotPosition) {
      const authToken = this.$route.params.authToken;
      const image = this.$refs.camera.capture();

      if ('selfie' === this.dotState.dotPosition) {
        this.uploadSelfieImage({ authToken, image });
      }

      this.addImage({
        dotPosition,
        image: image.split('base64,')[1],
      }).then(() => {
        if (this.isMovingDone) {
          this.uploadImages().then(() => {
            this.$router.push({
              name: 'authentication-identity',
              params: { authToken },
            });
          });
        }
      });
    },
    onStarted() {
      this.movePoint = true;
    },
    onStopped() {
      this.movePoint = false;
    },
    onStart() {
      this.dialog = true;
      this.isCameraOpen = true;
    },
    onStop() {
      this.$refs.camera.stop();
    },
    onError() {
      this.dialog = false;
      this.isCameraOpen = false;
      this.sendToPhone = true;
      this.cameraHasError = true;
      alert(this.$t('auths.liveliness.camera_error'));
    },
    onCameras() {
      this.$refs.camera.start();
    },
    uploadImages() {
      return this.$apollo.mutate({
        mutation: gql`
          mutation createLivenessCheck($token: String!, $input: [LivenessInput!]!) {
            createLivenessCheck(token: $token, input: $input) {
              id
              state
              livenessPercentage
            }
          }
        `,
        variables: {
          token: this.$route.params.authToken,
          input: this.images,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.camera-box {
  position: relative;
  min-height: 450px;
  height: 100%;

  video {
    border-radius: 8px;
    overflow: hidden;
  }
}
</style>
